import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-store';
import { DatePipe } from '@angular/common';

import { ApiService } from '../api.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, private cookieService: CookieService, public localStorageService: LocalStorageService,private datepipe: DatePipe,public apiService:ApiService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route.routeConfig.component.name == "LoginComponent")  {
      if (this.localStorageService.get('isLogin')) {
        this.router.navigate(['/dashboard']);
        return false;
      } else {
        return true;
      }
    }
    if (!this.localStorageService.get('isLogin')) {
      this.router.navigate(['/login']);
      this.localStorageService.remove['token'];
      return false;
    }else{
      let now = new Date().getTime();
      let setuptime = this.localStorageService.get('setuptime');
      let present = now - setuptime;
      if(present > 1 * 60 * 60 * 1000){
        alert("session expired");
        this.apiService.logout().subscribe((data:any)=>{});
        this.router.navigate(['/login']);
        this.localStorageService.remove('token');
        this.localStorageService.clear();
        return false
       
      }else{
        return true
      }
    }
  
  }
}