import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuardService } from './auth/auth.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: '404',
        component: P404Component,
        data: {
            title: 'Page 404'
        }
    },
    {
        path: '500',
        component: P500Component,
        data: {
            title: 'Page 500'
        }
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Login Page'
        }
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            title: 'Register Page'
        }
    },
    // {
        //   path:'partner/logistic',
        //   redirectTo:'logistic'
    // },
    // {
        //   path:'partner/farmer',
        //   redirectTo:'farmers'
    // },
    // {
        //   path:'partner/veterinarian',
        //   redirectTo:'veterinarian'
    // },
    // {
        //   path:'partner/trader',
        //   redirectTo:'trader'
    // },
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: './views/dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'animal',
                loadChildren: './views/cattle/cattle.module#CattleModule'
            },
            {
                path: 'employee',
                loadChildren: './views/employee/employee.module#EmployeeModule'
            },
            {
                path: 'report',
                loadChildren: './views/report/report.module#ReportModule'
            },
            {
                path: 'partners',
                loadChildren: './views/partners/partners.module#PartnersModule'
            },
            {
                path: 'partner/logistic',
                loadChildren: './views/logistics/logistics.module#LogisticsModule'
            },
            {
                path: 'partner/farmer',
                loadChildren: './views/farmers/farmers.module#FarmersModule'
            },
            {
                path: 'partner/trader',
                loadChildren: './views/traders/traders.module#TradersModule'
            },
            {
                path: 'partner/veterinarian',
                loadChildren: './views/veterinarian/veterinarian.module#VeterinarianModule'
            },
            {
                path: 'partner/entrepreneur',
                loadChildren: './views/village-entrepreneur/village-entrepreneur.module#VillageEntrepreneurModule'
            },
            {
                path: 'logs',
                loadChildren: './views/logs/logs.module#LogsModule'
            },
            {
                path: 'request',
                loadChildren: './views/request/request.module#RequestModule'
            },
            {
                path: 'liveasset',
                loadChildren: './views/liveasset/liveassets.module#LiveassetsModule'
            },
            {
                path: 'price/buffalo',
                loadChildren: './views/price/buffalo/buffalo.module#BuffaloModule'
            },
            {
                path: 'price/cow',
                loadChildren: './views/price/cow/cow.module#CowModule'
            },
            {
                path: 'price/goat',
                loadChildren: './views/price/goat/goat.module#GoatModule'
            },
            {
                path: 'price/sheep',
                loadChildren: './views/price/sheep/sheep.module#SheepModule'
            },
            {
                path: 'price/swine',
                loadChildren: './views/price/swine/swine.module#SwineModule'
            },
            {
                path: 'price/age',
                loadChildren: './views/price/swine/swine.module#SwineModule'
            },
            {
                path: 'prices',
                loadChildren: './views/price/price.module#PriceModule'
            },
            {
                path: 'insurance',
                loadChildren: './views/insurance/insurance.module#InsuranceModule'
            },
            {
                path: 'state',
                loadChildren: './views/state/state.module#StateModule'
            },
            {
                path: 'villages',
                loadChildren: './views/village/village.module#VillageModule'
            },
            {
                path: 'district',
                loadChildren: './views/district/district.module#DistrictModule'
            },
            {
                path: 'payment',
                loadChildren: './views/payment/payment.module#PaymentModule'
            }
        ]
    },
    { path: '**', component: P404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
