import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, public localStorageService: LocalStorageService) {
    }
  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            catchError((error: HttpErrorResponse) => {
            let errorMsg = '';
            // console.log(error);
            if(error.status == 403 && error instanceof HttpErrorResponse && error.error.data == "Invalid token"){
                // console.log("Error Log 403");
                // console.log(error.error.data);
                
                this.localStorageService.remove('token');
                this.localStorageService.clear();
                this.router.navigate(['/login']); 
            }
            
            return throwError(error);
            })
        )
    }
    }