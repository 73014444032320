import { Component, OnDestroy,OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../api.service';
import * as CryptoJS from 'crypto-js'
import { LocalStorageService } from 'ngx-store';
import { TranslateService } from '@ngx-translate/core';
import * as socketIo from 'socket.io-client';
import * as ws from 'ws';

import { default as config } from './../../../config';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public navItems: any[] = [];
  public navItemsenglish: any[] = [];
  public navItemstelugu: any[] = [];
  public navItemsdup:any[] = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  notificatiocount ;
  notificationdata:any[] = [];
  notificationdatatemp:any[] = [];
   lang = '';
   showalert:boolean = false;
  SOCKET_URL = config.WEB_SOCKET_URL;


  public element: HTMLElement = document.body;
  constructor(private route: Router,private cookieService: CookieService,private apiService: ApiService,public localStorageService: LocalStorageService,private translate: TranslateService) {

      this.lang = this.localStorageService.get('lang');
     translate.setDefaultLang(this.localStorageService.get('lang'));


    this.apiService.language$.subscribe(lang => {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.localStorageService.set('lang',lang);
      if(lang == 'en'){
        this.lang = lang;
         this.navItemsenglish = [];
         this.navItemsenglish = this.getMenus('en');
      }else if (lang ==  'te'){
        this.lang = lang;
         this.navItemstelugu = [];
         this.navItemstelugu = this.getMenus('te');
      }
    });
    if(this.localStorageService.get('lang') == 'en'){
      this.navItemsenglish = this.getMenus('en');
    }else if (this.localStorageService.get('lang') == 'te'){
      this.navItemstelugu = this.getMenus('te');
    }

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });
    this.element = document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    this.apiService.setToken(this.localStorageService.get('token'),this.localStorageService.get('ip'));

  }
  ngOnInit(){
    this.notificatiocount = this.localStorageService.get('notificationcount');
    // const socket = socketIo(this.SOCKET_URL);
    this.getOffline();
    //const url = 'wss://api.ipashumart.com/';
        const connection = new WebSocket(this.SOCKET_URL)

        connection.onopen = () => {
          connection.send('Message From Client')
        }

        connection.onerror = (error) => {
          console.log(`WebSocket error: ${error}`)
        }

        connection.onmessage = (e) => {
          if(e.data){
            this.getOffline();
        //     let data = JSON.parse(e.data);
        // this.notificatiocount = +this.localStorageService.get('notificationcount') + 1;
        // this.localStorageService.set('notificationcount',this.notificatiocount);
        // this.showalert = true;
        // this.notificationdata.unshift(data);
        // this.notificationdata = this.notificationdata.splice(0,5);

          }
        }

  }
  Getnotification(){
    this.getOffline();
    // this.notificatiocount = 0;
    // //this.localStorageService.remove
    // this.localStorageService.remove('notificationcount');
    // this.localStorageService.set('notificationcount',0);
    // this.apiService.updateNotification().subscribe((data:any)=>{

    // })
  }
  getMenus(lang) {
    var ciphertext = this.localStorageService.get('menu');
    var bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), 'pashumart123');

    let menus = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    menus.forEach((element,index) => {
      this.translate.get('menus.'+element.name).subscribe(translation=>{
        menus[index].name = translation;
      })
      if(element.children){
        element.children.forEach((child,index1) => {
          this.translate.get('menus.'+child.name).subscribe(translation=>{
            element.children[index1].name = translation;
          })
        });
      }
    });
    return menus;
  }
  updateNotification(notification_id){
    this.apiService.updateReadNotification(notification_id,{}).subscribe((data:any)=>{
      // console.log(data)
      this.getOffline();
    })
    

  }
  getOffline(){
    // console.log(this.localStorageService.get('login_id'))
    this.apiService.getNewNotifications(this.localStorageService.get('login_id')).subscribe((data:any)=>{
        let notificationdata = data.data;
         if(notificationdata){
        this.notificatiocount = notificationdata.length;
        this.localStorageService.set('notificationcount',this.notificatiocount);
        this.notificationdata = notificationdata;
        // console.log(this.notificationdata)
         }else{
          this.notificatiocount = 0;
         }

    })
    

    // this.apiService.getOffineNotification().subscribe((data:any)=>{
    //   let notificationdata = data.data;
    //    if(notificationdata){
    //    this.notificatiocount = notificationdata.length;
    //   this.localStorageService.set('notificationcount',this.notificatiocount);
    //   this.notificationdatatemp = data.data;
    //   this.notificationdata = this.notificationdatatemp.splice(0,5);
    //   }
    // })
  }
  ngOnDestroy(): void {
    this.changes.disconnect();
  }
  logout() {
    this.localStorageService.clear();
    // this.cookieService.delete('isLogin');
    // this.cookieService.delete('ip');
    // this.cookieService.delete('menu');
    // this.cookieService.delete('token');
    // this.cookieService.delete('state_id');
    // this.cookieService.delete('partnerId');
    this.apiService.logout().subscribe((data:any)=>{
      if(data.data){
        // this.cookieService.deleteAll();
        this.route.navigateByUrl('/login');
      }

    }, error => {
      this.route.navigateByUrl('/login');

    });


  }
  language(a){
    this.apiService.langSubject.next(a);
    this.localStorageService.set("lang",a);
  }
}
